import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as Styled from './styles';
import { colors } from '#styles.variables';

import { Icon, Logotype } from '#ui-kit';
import { IconNames } from '#ui-kit.types';

import { NavigationItemProps } from './types/Sidebar.types';

const NAVIGATION: NavigationItemProps[] = [
  {
    name: 'Пользователи',
    iconName: IconNames.users,
    to: '/users',
    children: [
      {
        name: 'Роли',
        to: '/roles',
      },
    ],
  },
  {
    name: 'Рестораны',
    iconName: IconNames.location,
    to: '/restaurants',
    children: [
      {
        name: 'Города',
        to: '/cities',
      },
      {
        name: 'Зоны доставки',
        to: '/delivery-zones',
      },
    ],
  },
  {
    name: 'Заказы',
    iconName: IconNames.orders,
    to: '/orders',
  },
  {
    name: 'Онбординг',
    iconName: IconNames.onbording,
    to: '/onbording',
  },
  {
    name: 'Акции и купоны',
    iconName: IconNames.coupons,
    to: '/promotions',
  },
  {
    name: 'Документы',
    iconName: IconNames.document,
    to: '/documents',
  },
  {
    name: 'Франчайзинг',
    iconName: IconNames.shop,
    to: '/franchising',
  },
];

const BOTTOM_NAVIGATION: NavigationItemProps[] = [
  {
    name: 'Настройки приложения',
    iconName: IconNames.settings,
    to: '/settings',
  },
  {
    name: 'Поддержка',
    iconName: IconNames.chat,
    to: '/support',
  },
];

const NavigationGroup: React.FC<NavigationItemProps> = ({
  name,
  children = [],
  iconName,
  to,
}) => {
  const [open, setIsOpen] = useState(false);

  return (
    <Styled.NavigationGroup>
      <Styled.NavigationAccordion to={to || '/'} open={open}>
        <Styled.AccordionContainer>
          {iconName && (
            <Icon
              width={24}
              height={24}
              name={iconName}
              fill={colors.black._40}
            />
          )}
          <Styled.RouteName className="navigation__name navigatio-accordion__name">
            {name}
          </Styled.RouteName>
        </Styled.AccordionContainer>
        <Styled.NavigationChildIndicator />
      </Styled.NavigationAccordion>
      <Styled.Toggle
        open={open}
        onClick={() => setIsOpen(!open)}
        className="accordion__indicator"
      >
        <Icon
          name={IconNames['arrow-right']}
          width={20}
          height={20}
          fill={colors.black._40}
        />
      </Styled.Toggle>
      <Styled.NavigationChildren aria-expanded={!open}>
        {children.map((element, index) => (
          <Styled.NavigationChildContainer
            key={`navigation-child-${index}`}
            to={element.to}
          >
            <Styled.RouteName className="navigation__name">
              {element.name}
            </Styled.RouteName>
            <Styled.NavigationChildIndicator />
          </Styled.NavigationChildContainer>
        ))}
      </Styled.NavigationChildren>
    </Styled.NavigationGroup>
  );
};

const SingleNavigationItem: React.FC<Omit<NavigationItemProps, 'children'>> = ({
  name,
  to,
  iconName,
}) => (
  <Styled.NavigationItem to={to || '/'}>
    {iconName && (
      <Icon width={24} height={24} name={iconName} fill={colors.black._40} />
    )}
    <Styled.RouteName className="navigation__name">{name}</Styled.RouteName>
    <Styled.NavigationChildIndicator />
  </Styled.NavigationItem>
);

const NavigationItem: React.FC<NavigationItemProps> = ({
  name,
  children,
  to,
  iconName,
}) => {
  if (children?.length) {
    return <NavigationGroup {...{ name, children, to, iconName }} />;
  }

  return <SingleNavigationItem {...{ name, to, iconName }} />;
};

const Sidebar: React.FC = () => {
  const [scrollTop, setScrollTop] = useState(true);

  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback((div: HTMLDivElement) => {
    if (div.scrollTop >= 5) {
      setScrollTop(false);
    } else {
      setScrollTop(true);
    }
  }, []);

  useEffect(() => {
    const div = ref.current;

    if (div) {
      div.addEventListener('scroll', () => handleScroll(div));
    }
  }, [handleScroll, ref.current]);

  return (
    <Styled.Container ref={ref}>
      <Styled.LogoContainer shifted={!scrollTop}>
        <Logotype />
      </Styled.LogoContainer>
      <Styled.Navigation>
        {NAVIGATION.map((element, index) => (
          <NavigationItem key={`nav-${index}`} {...element} />
        ))}
      </Styled.Navigation>
      <Styled.Separator />
      <Styled.Navigation>
        {BOTTOM_NAVIGATION.map((element, index) => (
          <NavigationItem key={`bottom-nav-${index}`} {...element} />
        ))}
      </Styled.Navigation>
    </Styled.Container>
  );
};

export default Sidebar;
