import { useEffect, useState } from 'react';
import * as Styled from './styles';
import { colors } from '#styles.variables';
import { Button, Typography, toastify } from '#ui-kit';
import { ButtonVariant, TypographySize } from '#ui-kit.types';
import { formatInteger } from '#utils';
import debug from '#services/debug.service';
import FilesService from '#services/files/files.service';
import { PromotionsService } from '#services/promotions';
import {
  PromotionDataType,
  PromotionStatusEnum,
} from '#services/promotions/dto/get-promotions.dto';
import TableView from '../Table';
import UploadPromoPhotoView from '../UploadPromoPhotoView';
import PriorityInput from './PriorityInput';
import { PromotionViewProps } from './types/PromotionView.types';

const MIN_PRIORITY = 1;
const FORMATTED_MIN_PRIORITY = formatInteger(MIN_PRIORITY);
const MAX_PRIORITY = 99999;
const FORMATTED_MAX_PRIORITY = formatInteger(MAX_PRIORITY);
const PRIORITY_REGEX = /^[1-9]{1}\d*$/;

export type FileType = File | number | null;

type PromoDataType = Omit<PromotionDataType, 'sortOrder'> & {
  sortOrder: string | number | bigint | null;
};

export type FilesType = {
  mainPageFileId: FileType;
  promotionPageFileId: FileType;
  discountsPageFileId: FileType;
  couponsPageFileId: FileType;
};

enum ActionType {
  UpdateData = 'UpdateData',
  CancelChanges = 'CancelChanges',
}

const PromotionView: React.FC<PromotionViewProps> = ({
  data,
  getPromotion,
}) => {
  const textareaStyle = {
    width: '100%',
    height: '150px',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginBottom: '18px',
  };

  const promotionsService = new PromotionsService();
  const filesService = new FilesService();

  const {
    mindboxId,
    id,
    mainPageFileId,
    couponsPageFileId,
    discountsPageFileId,
    promotionPageFileId,
    type,
    startedAt,
    endsAt,
    code,
  } = data;

  const startDate = startedAt ? new Date(startedAt) : null;
  const endDate = endsAt ? new Date(endsAt) : null;

  const [promoData, setPromoData] = useState<PromoDataType>(data);

  const initialFilesObject = {
    mainPageFileId: null,
    promotionPageFileId: null,
    discountsPageFileId: null,
    couponsPageFileId: null,
  };

  const [files, setFiles] = useState<FilesType>(initialFilesObject);
  const [loading, setLoading] = useState(false);
  const [priorityError, setPriorityError] = useState('');

  const onChangePromoData = <T extends keyof PromoDataType>(
    key: T,
    value: PromoDataType[T],
  ) => {
    setPromoData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const getChangedData = (): PromoDataType => {
    const changedData = { ...promoData };
    Object.keys(changedData).forEach((key) => {
      if (
        changedData[key as keyof PromoDataType] ===
        data[key as keyof PromoDataType]
      ) {
        delete changedData[key as keyof PromoDataType];
      }
    });
    return changedData;
  };

  const getNewFiles = (): FilesType => {
    const changedFilesData: { [key in keyof FilesType]: FileType } = {
      ...files,
    };
    Object.keys(files).forEach((fileKey) => {
      if (files[fileKey as keyof FilesType] === null) {
        delete changedFilesData[fileKey as keyof FilesType];
      }
    });
    return changedFilesData;
  };

  const onPressBtn = async (actionType: ActionType) => {
    if (actionType === ActionType.UpdateData) {
      setLoading(true);
    }
    const changedData = getChangedData();
    const newFiles = getNewFiles();
    const filesWasChanged = !!Object.keys(newFiles).length;
    const filesDataForRequest = {};

    if (Object.keys(changedData).length > 0 || filesWasChanged) {
      switch (actionType) {
        case ActionType.UpdateData: {
          try {
            if (filesWasChanged) {
              await Promise.all(
                Object.keys(newFiles).map(async (key) => {
                  const file = newFiles[key as keyof FilesType];

                  if (file) {
                    const newFile = await filesService.uploadFile(file as File);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    filesDataForRequest[key as keyof FilesType] = newFile.id;
                    setFiles(initialFilesObject);
                  }
                }),
              );
            }
            await promotionsService.updatePromotionById({
              data: { ...changedData, ...filesDataForRequest },
              promotionId: id,
            });
            await getPromotion(false);
            toastify.success('Данные успешно обновлены.');
          } catch (e) {
            setLoading(false);
            toastify.error('Не удалось обновить данные.');
          } finally {
            setLoading(false);
          }
          break;
        }
        case ActionType.CancelChanges: {
          try {
            await getPromotion();
            toastify.success('Изменения отменены');
          } catch (e) {
            debug.error('🧑🏻‍💻 err:', e);
          }
          break;
        }
        default: {
          return;
        }
      }
    } else {
      setLoading(false);
      toastify.error('Отредактируйте акцию');
    }
  };

  const handlePriorityChange = (rawValue: string) => {
    const value = rawValue.replace(/\s+/g, '');

    if (value === '') {
      onChangePromoData('sortOrder', null);
      setPriorityError('');
      return;
    }

    if (!PRIORITY_REGEX.test(value)) {
      onChangePromoData('sortOrder', rawValue);
      const error = `Введите числовое значение от ${FORMATTED_MIN_PRIORITY} до ${FORMATTED_MAX_PRIORITY}`;
      setPriorityError(error);
      return;
    }

    const sortOrder = BigInt(value);

    if (sortOrder >= MIN_PRIORITY && sortOrder <= MAX_PRIORITY) {
      onChangePromoData('sortOrder', Number(sortOrder));
      setPriorityError('');
    } else {
      onChangePromoData('sortOrder', sortOrder);
      const error = `Введите числовое значение от ${FORMATTED_MIN_PRIORITY} до ${FORMATTED_MAX_PRIORITY}`;
      setPriorityError(error);
    }
  };

  useEffect(() => {
    if (data) {
      setPromoData(data);
    }
  }, [data]);

  const priorityDisplayValue =
    typeof promoData.sortOrder === 'number' ||
    typeof promoData.sortOrder === 'bigint'
      ? formatInteger(promoData.sortOrder)
      : promoData.sortOrder ?? '';

  const isSaveButtonDisabled = !!priorityError;

  return (
    <>
      <Styled.Container>
        <Styled.FlexContainer>
          <Typography>ID акции (из Mindbox):</Typography>
          <Typography color={colors.primary._100} size={TypographySize.m}>
            {mindboxId}
          </Typography>
        </Styled.FlexContainer>
        <Styled.FlexContainer>
          <Typography>Тип:</Typography>
          <Typography color={colors.primary._100} size={TypographySize.m}>
            {type}
          </Typography>
        </Styled.FlexContainer>
        {code && (
          <Styled.FlexContainer>
            <Typography>Код:</Typography>
            <Typography color={colors.primary._100} size={TypographySize.m}>
              {code}
            </Typography>
          </Styled.FlexContainer>
        )}
        {startDate && (
          <Styled.FlexContainer>
            <Typography>Дата начала:</Typography>
            <Typography color={colors.primary._100} size={TypographySize.m}>
              {`${startDate.toLocaleDateString()} ${startDate.toLocaleTimeString()}`}
            </Typography>
          </Styled.FlexContainer>
        )}
        {endDate && (
          <Styled.FlexContainer>
            <Typography>Дата окончания:</Typography>
            <Typography color={colors.primary._100} size={TypographySize.xl}>
              {`${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`}
            </Typography>
          </Styled.FlexContainer>
        )}
        <Styled.FlexContainer>
          <Typography>Приоритет:</Typography>
          <PriorityInput
            value={priorityDisplayValue}
            onChange={handlePriorityChange}
            hint="Чем меньше значение, тем позиция ближе к началу списка"
            placeholder={`от ${FORMATTED_MIN_PRIORITY} до ${FORMATTED_MAX_PRIORITY}`}
            error={priorityError}
          />
        </Styled.FlexContainer>
      </Styled.Container>
      <Styled.PhotosContainer>
        <UploadPromoPhotoView
          id={promotionPageFileId}
          type="promotionPageFileId"
          name="Экран акции"
          onRemovePhoto={() => onChangePromoData('promotionPageFileId', null)}
          setFiles={setFiles}
        />
        <UploadPromoPhotoView
          id={couponsPageFileId}
          type="couponsPageFileId"
          name="Экран Список Купонов"
          onRemovePhoto={() => onChangePromoData('couponsPageFileId', null)}
          setFiles={setFiles}
        />
        <UploadPromoPhotoView
          id={discountsPageFileId}
          type="discountsPageFileId"
          name="Экран Список Акций"
          onRemovePhoto={() => onChangePromoData('discountsPageFileId', null)}
          setFiles={setFiles}
        />
        <UploadPromoPhotoView
          id={mainPageFileId}
          type="mainPageFileId"
          name="Экран Главная"
          onRemovePhoto={() => onChangePromoData('mainPageFileId', null)}
          setFiles={setFiles}
        />
      </Styled.PhotosContainer>
      <Styled.Container>
        <Styled.TextContainer>
          <Typography>Активна</Typography>
        </Styled.TextContainer>
        <Styled.FilterContainer>
          <Styled.FilterItem
            style={{
              background:
                promoData.internalStatus === PromotionStatusEnum.ACTIVE
                  ? colors.primary._100
                  : colors.black._02,
            }}
            onClick={() =>
              onChangePromoData('internalStatus', PromotionStatusEnum.ACTIVE)
            }
          >
            <p
              className="filter_item_text"
              style={{
                color:
                  promoData.internalStatus === PromotionStatusEnum.ACTIVE
                    ? colors.black._00
                    : colors.black._80,
              }}
            >
              Да
            </p>
          </Styled.FilterItem>
          <Styled.FilterItem
            style={{
              background:
                promoData.internalStatus !== PromotionStatusEnum.ACTIVE
                  ? colors.primary._100
                  : colors.black._02,
            }}
            onClick={() =>
              onChangePromoData('internalStatus', PromotionStatusEnum.DISABLED)
            }
          >
            <p
              className="filter_item_text"
              style={{
                color:
                  promoData.internalStatus !== PromotionStatusEnum.ACTIVE
                    ? colors.black._00
                    : colors.black._80,
              }}
            >
              Нет
            </p>
          </Styled.FilterItem>
        </Styled.FilterContainer>
        {/* <Toggle checked={promoData.internalStatus === PromotionStatusEnum.ACTIVE} onChange={() => onChangePromoData('internalStatus', promoData.internalStatus === PromotionStatusEnum.ACTIVE ? PromotionStatusEnum.DISABLED : PromotionStatusEnum.ACTIVE )}/> */}
      </Styled.Container>

      <Styled.Container>
        <Styled.TextContainer>
          <Typography>Вывод на главный экран</Typography>
        </Styled.TextContainer>
        <Styled.FilterContainer>
          <Styled.FilterItem
            style={{
              background: promoData.isShowOnMainPage
                ? colors.primary._100
                : colors.black._02,
            }}
            onClick={() => onChangePromoData('isShowOnMainPage', true)}
          >
            <p
              className="filter_item_text"
              style={{
                color: promoData.isShowOnMainPage
                  ? colors.black._00
                  : colors.black._80,
              }}
            >
              Да
            </p>
          </Styled.FilterItem>
          <Styled.FilterItem
            style={{
              background: !promoData.isShowOnMainPage
                ? colors.primary._100
                : colors.black._02,
            }}
            onClick={() => onChangePromoData('isShowOnMainPage', false)}
          >
            <p
              className="filter_item_text"
              style={{
                color: !promoData.isShowOnMainPage
                  ? colors.black._00
                  : colors.black._80,
              }}
            >
              Нет
            </p>
          </Styled.FilterItem>
        </Styled.FilterContainer>
        {/* <Toggle checked={promoData.internalStatus === PromotionStatusEnum.ACTIVE} onChange={() => onChangePromoData('internalStatus', promoData.internalStatus === PromotionStatusEnum.ACTIVE ? PromotionStatusEnum.DISABLED : PromotionStatusEnum.ACTIVE )}/> */}
      </Styled.Container>
      <Styled.Container>
        <Styled.TextContainer>
          <Typography>Название акции</Typography>
        </Styled.TextContainer>
        <textarea
          id="userAgreement"
          style={{ ...textareaStyle, boxSizing: 'border-box' }}
          value={promoData.name}
          onChange={(e) => onChangePromoData('name', e.target.value)}
        />
      </Styled.Container>
      <Styled.Container>
        <Styled.TextContainer>
          <Typography>Краткий текст</Typography>
        </Styled.TextContainer>
        <textarea
          id="userAgreement"
          style={{ ...textareaStyle, boxSizing: 'border-box' }}
          value={promoData.description}
          onChange={(e) => onChangePromoData('description', e.target.value)}
        />
      </Styled.Container>
      <Styled.Container>
        <Styled.TextContainer>
          <Typography>Полный текст</Typography>
        </Styled.TextContainer>
        <textarea
          id="userAgreement"
          style={{ ...textareaStyle, boxSizing: 'border-box' }}
          value={promoData.fullDescription}
          onChange={(e) => onChangePromoData('fullDescription', e.target.value)}
        />
      </Styled.Container>
      <Styled.ButtonsContainer>
        <Styled.ButtonContainer>
          <Button
            loading={loading}
            label="Сохранить изменения"
            onClick={() => onPressBtn(ActionType.UpdateData)}
            disabled={isSaveButtonDisabled}
          />
        </Styled.ButtonContainer>
        <Styled.ButtonContainer>
          <Button
            label="Отменить изменения"
            onClick={() => onPressBtn(ActionType.CancelChanges)}
            variant={ButtonVariant.Outline}
          />
        </Styled.ButtonContainer>
      </Styled.ButtonsContainer>
      <TableView promotionId={data.id} />
    </>
  );
};

export default PromotionView;
