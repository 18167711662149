import { ptr } from '#styles.utils';

import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';

import { StatusBadge } from '#components';
import { notAvailableValue, prettifyDate } from '#utils';
import { OrderDeliveryTypeEnum } from '#services/orders/enums/order-delivery-type.enum';
import { OrderInternalStatusEnum } from '#services/orders/enums/order-internal-status.enum';
import { OrderPaymentStatusEnum } from '#services/orders/enums/order-payment-status.enum';
import { OrderPOSStatusEnum } from '#services/orders/enums/order-pos-status.enum';
import { OrderResponse } from '#services/orders/responses/order.response';

import * as Styled from './style';

import {
  OrderDeliveryTypeBadgeMap,
  OrderInternalStatusBadgeMap,
  OrderPOSStatusBadgeMap,
  OrderTransactionStatusBadgeMap,
} from 'pages/Order/constants';

type ViewProps = {
  data: OrderResponse;
};

type OrderSummaryData = Pick<
  OrderResponse,
  | 'id'
  | 'posId'
  | 'createdAt'
  | 'deliveredAt'
  | 'deliveryType'
  | 'orderNumber'
  | 'pickedAt'
  | 'posStatus'
  | 'paymentStatus'
  | 'internalStatus'
  | 'errorMessage'
>;

const orderSummaryLocalizationMap: Record<keyof OrderSummaryData, string> = {
  id: 'Идентификатор',
  posId: 'Идентификатор POS',
  orderNumber: 'Читаемый номер заказа',
  internalStatus: 'Системный статус',
  posStatus: 'Статус в ПОС',
  paymentStatus: 'Статус транзакции оплаты',
  createdAt: 'Создан в',
  deliveredAt: 'Доставлен в',
  pickedAt: 'Забран в',
  deliveryType: 'Способ доставки',
  errorMessage: 'Причина ошибки',
};

function getOrderPropertyComponent<T>(
  propertyName: keyof OrderSummaryData,
  value: T,
): React.JSX.Element {
  switch (propertyName) {
    case 'posStatus':
      return (
        <Styled.OrderStatusContainer>
          <StatusBadge<typeof OrderPOSStatusEnum>
            {...{
              value: value as OrderPOSStatusEnum,
              data: OrderPOSStatusBadgeMap,
            }}
          />
        </Styled.OrderStatusContainer>
      );
    case 'internalStatus':
      return (
        <Styled.OrderStatusContainer>
          <StatusBadge<typeof OrderInternalStatusEnum>
            {...{
              value: value as OrderInternalStatusEnum,
              data: OrderInternalStatusBadgeMap,
            }}
          />
        </Styled.OrderStatusContainer>
      );
    case 'deliveryType':
      return (
        <Styled.OrderStatusContainer>
          <StatusBadge<typeof OrderDeliveryTypeEnum>
            {...{
              value: value as OrderDeliveryTypeEnum,
              data: OrderDeliveryTypeBadgeMap,
            }}
          />
        </Styled.OrderStatusContainer>
      );
    case 'createdAt':
      return (
        <Typography
          size={TypographySize.m}
          tag={TypographyTag.span}
          weight={TypographyWeight.Medium}
        >
          {value ? prettifyDate(value as string) : notAvailableValue(value)}
        </Typography>
      );
    case 'deliveredAt':
      return (
        <Typography
          size={TypographySize.m}
          tag={TypographyTag.span}
          weight={TypographyWeight.Medium}
        >
          {value ? prettifyDate(value as string) : notAvailableValue(value)}
        </Typography>
      );
    case 'pickedAt':
      return (
        <Typography
          size={TypographySize.m}
          tag={TypographyTag.span}
          weight={TypographyWeight.Medium}
        >
          {value ? prettifyDate(value as string) : notAvailableValue(value)}
        </Typography>
      );
    case 'paymentStatus':
      return (
        <Styled.OrderStatusContainer>
          <StatusBadge<typeof OrderPaymentStatusEnum>
            {...{
              value: value as OrderPaymentStatusEnum,
              data: OrderTransactionStatusBadgeMap,
            }}
          />
        </Styled.OrderStatusContainer>
      );
    default:
      return (
        <Typography
          size={TypographySize.m}
          tag={TypographyTag.span}
          weight={TypographyWeight.Medium}
        >
          {notAvailableValue(value)}
        </Typography>
      );
  }
}

const OrderSummary: React.FC<ViewProps> = ({
  data,
  // onPressCancelOrderBtn,
  // onPressReturnMoneyBtn,
  // onPressFinishOrderBtn,
  // loading,
}) => (
  <>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.span}
      style={{ marginBottom: ptr(20) }}
    >
      Сводная информация
    </Typography>
    <Table
      {...{
        // loading,
        header: {
          cells: {},
        },
        rows: Object.keys(orderSummaryLocalizationMap).map((orderDataKey) => ({
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Light}
                  key={`summary-${orderDataKey}`}
                >
                  {
                    orderSummaryLocalizationMap[
                      orderDataKey as keyof OrderSummaryData
                    ]
                  }
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: getOrderPropertyComponent(
                orderDataKey as keyof OrderSummaryData,
                data[orderDataKey as keyof OrderSummaryData],
              ),
            },
          },
        })),
      }}
    />
  </>
);

export default OrderSummary;
