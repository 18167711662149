import { useEffect, useState } from 'react';
import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';
import debug from '#services/debug.service';
import { OnboardingService } from '../../services/onbording';
import { OnboardingDataType } from '../../services/onbording/dto/upload-board.dto';
import { LoadingType } from '../Promotion';
import OnboardingItem from './components/OnboardingItem';
import './onbording.css';

export default () => {
  const onboardingService = new OnboardingService();
  const [loading, setLoading] = useState({
    globalLoading: false,
  });
  const [data, setData] = useState<OnboardingDataType[]>([]);

  const getOnboarding = async (shouldLoad = true) => {
    try {
      if (shouldLoad) {
        setLoading((old: LoadingType) => ({
          ...old,
          globalLoading: true,
        }));
      }
      const response = await onboardingService.getOnboarding({
        page: '1',
        size: '20',
      });

      setData(response);
    } catch (e) {
      debug.error('', e);
    } finally {
      if (shouldLoad) {
        setLoading((old: LoadingType) => ({
          ...old,
          globalLoading: false,
        }));
      }
    }
  };

  useEffect(() => {
    getOnboarding();
  }, []);

  if (loading.globalLoading) {
    return <LoadingContent />;
  }

  return (
    <div className="onbording">
      <Head
        {...{
          label: 'Онбординг',
          underlineVariant: HeadUnderlineVariant.Primary,
        }}
      />
      {data.map((item) => (
        <OnboardingItem item={item} />
      ))}
    </div>
  );
};
