import { Typography } from '#ui-kit';
import { TypographySize, TypographyTag, TypographyWeight } from '#ui-kit.types';
import { notAvailableValue } from '#utils';
import { OrderItemResponseType } from '#services/orders/responses/order-item.response';
import LinePrice from '../LinePrice';
import LineQuantity from '../LineQuantity';
import LineQuantityCounter from '../LineQuantityCounter';
import * as Styled from './style';

type OrderLineType = Pick<
  OrderItemResponseType,
  | 'id'
  | 'lineId'
  | 'images'
  | 'name'
  | 'prices'
  | 'quantity'
  | 'refundedQuantity'
  | 'appliedPromotions'
> & {
  subLines?: OrderSubLineType[];
  type: 'COUPON' | 'PRODUCT' | 'MODIFIER' | 'COUPON_PRODUCT';
};

type OrderSubLineType = Pick<
  OrderLineType,
  | 'id'
  | 'lineId'
  | 'images'
  | 'name'
  | 'prices'
  | 'quantity'
  | 'refundedQuantity'
  | 'appliedPromotions'
  | 'type'
>;

type RefundItemMetadata = {
  max: number;
  curr: number;
  fullQuantity: number;
  pricePerItem: number;
};

type ViewMode =
  | {
      mode: 'REFUND';
      refundItemsMap: Record<number, RefundItemMetadata>;
      partialRefundAvailable: boolean;
      onAddItemToRefund: (
        lineId: number,
        quantity: number,
        itemFullPrice: number,
      ) => void;
      onDeleteItemToRefund: (
        lineId: number,
        quantity: number,
        itemFullPrice: number,
      ) => void;
    }
  | {
      mode: 'VIEW';
    };

type OrderProductLineProps = {
  data: OrderLineType;
  options: ViewMode;
};

const OrderProductLine: React.FC<OrderProductLineProps> = ({
  data,
  options,
}) => (
  <Styled.ReceiptLine>
    <Styled.ReceiptLineProduct>
      <Typography
        size={TypographySize.m}
        tag={TypographyTag.span}
        weight={TypographyWeight.Medium}
        style={{ width: '50%' }}
      >
        {notAvailableValue(data.name)}
      </Typography>
      <Typography
        size={TypographySize.m}
        tag={TypographyTag.span}
        weight={TypographyWeight.Medium}
      >
        {options.mode === 'REFUND' ? (
          <LineQuantityCounter
            data={{
              quantity: data.quantity,
              refundedQuantity: data.refundedQuantity,
              prices: data.prices,
              isModifier: false,
              lineId: data.lineId,
              refundItemsMap: options.refundItemsMap,
              onMinus: options.onDeleteItemToRefund,
              onPlus: options.onAddItemToRefund,
              partialRefundAvailable: options.partialRefundAvailable,
            }}
          />
        ) : (
          <LineQuantity
            data={{
              quantity: data.quantity,
              refundedQuantity: data.refundedQuantity,
              isModifier: false,
            }}
          />
        )}
      </Typography>
      <Typography
        size={TypographySize.m}
        tag={TypographyTag.span}
        weight={TypographyWeight.Medium}
        style={{ width: '18.5%', textAlign: 'end' }}
      >
        <LinePrice data={{ ...data.prices, isModifier: false }} />
      </Typography>
    </Styled.ReceiptLineProduct>
    {data.subLines &&
      data.subLines.map((modifier) => (
        <Styled.ReceiptLineModifier key={`line-${modifier.lineId}`}>
          <Typography
            size={TypographySize.s}
            tag={TypographyTag.span}
            weight={TypographyWeight.Light}
            style={{ width: '44%' }}
          >
            {notAvailableValue(modifier.name)}
          </Typography>
          <Typography
            size={TypographySize.m}
            tag={TypographyTag.span}
            weight={TypographyWeight.Light}
            style={{ maxWidth: '20%' }}
          >
            {modifier.type !== 'COUPON_PRODUCT' ? (
              options.mode === 'REFUND' ? (
                <LineQuantityCounter
                  data={{
                    prices: modifier.prices,
                    quantity: modifier.quantity,
                    refundedQuantity: modifier.refundedQuantity,
                    isModifier: false,
                    lineId: modifier.lineId,
                    refundItemsMap: options.refundItemsMap,
                    onMinus: options.onDeleteItemToRefund,
                    onPlus: options.onAddItemToRefund,
                    partialRefundAvailable: options.partialRefundAvailable,
                  }}
                />
              ) : (
                <LineQuantity
                  data={{
                    quantity: modifier.quantity,
                    refundedQuantity: modifier.refundedQuantity,
                    isModifier: true,
                  }}
                />
              )
            ) : (
              <></>
            )}
          </Typography>
          <Typography
            size={TypographySize.m}
            tag={TypographyTag.span}
            weight={TypographyWeight.Light}
            style={{ width: '20%', textAlign: 'end' }}
          >
            {modifier.type !== 'COUPON_PRODUCT' && (
              <LinePrice data={{ ...modifier.prices, isModifier: true }} />
            )}
          </Typography>
        </Styled.ReceiptLineModifier>
      ))}
  </Styled.ReceiptLine>
);

export default OrderProductLine;
