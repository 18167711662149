import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { ptr } from '#styles.utils';
import { Button, Typography, toastify } from '#ui-kit';

import { ButtonVariant, TypographySize, TypographyTag } from '#ui-kit.types';
import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';

import { OrdersService } from '#services/orders';
import { RefundOrderDto } from '#services/orders/dto/refund-order.dto';
import { OrderDeliveryTypeEnum } from '#services/orders/enums/order-delivery-type.enum';
import { OrderInternalStatusEnum } from '#services/orders/enums/order-internal-status.enum';
import { OrderPaymentStatusEnum } from '#services/orders/enums/order-payment-status.enum';
import { OrderPaymentEnum } from '#services/orders/enums/order-payment.enum';
import { OrderPOSStatusEnum } from '#services/orders/enums/order-pos-status.enum';
import { RefundOrderTypeEnum } from '#services/orders/enums/refund-type.enum';
import { OrderResponse } from '#services/orders/responses/order.response';

import OrderAddress from './containers/OrderAddress';
import OrderPayments from './containers/OrderPayments';
import OrderRestaurant from './containers/OrderRestaurant';
import OrderSummary from './containers/OrderSummary';
import Receipt from './containers/Receipt';
import * as Styled from './style';

export type LoadingType = {
  globalLoading: boolean;
  cancelOrderLoading: boolean;
  returnMoneyLoading: boolean;
  finishOrderLoading: boolean;
};

type RefundItemMetadata = {
  max: number;
  curr: number;
  fullQuantity: number;
  pricePerItem: number;
};

export default () => {
  const ordersService = new OrdersService();

  const [order, setOrder] = useState<OrderResponse | null>(null);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState<boolean>(false);
  const [refundItemsMap, setRefundItemsMap] = useState<
    Record<number, RefundItemMetadata>
  >({});
  const [priceToRefund, setPriceToRefund] = useState<number>(0);
  const [isRefundAvailable, setIsRefundAvailable] = useState<boolean>(false);
  const [isRefundDeliveryPrice, setIsRefundDeliveryPrice] =
    useState<boolean>(false);

  const [loading, setLoading] = useState({
    globalLoading: false,
    cancelOrderLoading: false,
    returnMoneyLoading: false,
    finishOrderLoading: false,
  });

  const { id } = useParams();

  const partialRefundAvailable =
    order?.paymentStatus === OrderPaymentStatusEnum.CONFIRMED ||
    order?.paymentStatus === OrderPaymentStatusEnum.COMPLETED ||
    order?.paymentStatus === OrderPaymentStatusEnum.PARTIAL_REFUNDED ||
    order?.paymentStatus === OrderPaymentStatusEnum.PARTIAL_REVERSED;

  const checkIsRefundAvailable = () => {
    if (priceToRefund > 0) {
      setIsRefundAvailable(true);

      return true;
    }

    setIsRefundAvailable(false);

    return false;
  };

  const onRefundDeliveryPrice = () => {
    if (order?.prices?.deliveryPrice) {
      setIsRefundDeliveryPrice((oldIsRefundDeliveryPrice) => {
        const newIsRefundDeliveryPrice = !oldIsRefundDeliveryPrice;

        setPriceToRefund((oldPrice) =>
          newIsRefundDeliveryPrice
            ? oldPrice + order?.prices?.deliveryPrice
            : oldPrice - order?.prices?.deliveryPrice,
        );

        return newIsRefundDeliveryPrice;
      });
    }
  };

  useEffect(() => {
    if (
      order?.payments.paymentType === OrderPaymentEnum.BANK_CARD_IN_APP &&
      order?.paymentStatus !== OrderPaymentStatusEnum.REFUNDED &&
      order?.paymentStatus !== OrderPaymentStatusEnum.REJECTED &&
      order?.paymentStatus !== OrderPaymentStatusEnum.REVERSED &&
      order?.paymentStatus !== OrderPaymentStatusEnum.CANCELED
    ) {
      checkIsRefundAvailable();

      return;
    }

    setIsRefundAvailable(false);
  }, [isRefundDeliveryPrice, refundItemsMap, priceToRefund]);

  useEffect(() => {
    if (
      order?.deliveryType === OrderDeliveryTypeEnum.delivery &&
      !order?.isDeliveryRefunded &&
      !isRefundDeliveryPrice
    ) {
      onRefundDeliveryPrice();
    }
  }, [order]);

  const onAddItemToRefund = (lineId: number, quantity: number) => {
    if (!refundItemsMap[lineId]) {
      return;
    }

    if (refundItemsMap[lineId].curr + quantity <= refundItemsMap[lineId].max) {
      setRefundItemsMap((oldMap) => {
        const newMap: Record<number, RefundItemMetadata> = {};

        Object.assign(newMap, oldMap);

        newMap[lineId].curr += quantity;

        setPriceToRefund(
          (oldPriceToRefund) =>
            oldPriceToRefund + refundItemsMap[lineId].pricePerItem * quantity,
        );

        return newMap;
      });
    }
  };

  const recreateRefundItemsMap = (orderResponse: OrderResponse) => {
    let newPriceToRefund = 0;

    const newRefundItemsMap: Record<number, RefundItemMetadata> = {
      ...refundItemsMap,
    };

    orderResponse?.items?.forEach((item) => {
      const itemQuantityToRefund =
        order?.paymentStatus !== OrderPaymentStatusEnum.CONFIRMED
          ? item.quantity - item.refundedQuantity
          : 0;

      newRefundItemsMap[item.lineId] = {
        max: item.quantity - item.refundedQuantity,
        curr: itemQuantityToRefund,
        fullQuantity: item.quantity,
        pricePerItem: item.prices.discountedPrice / item.quantity,
      };

      if (item.quantity > 0) {
        newPriceToRefund +=
          itemQuantityToRefund * (item.prices.discountedPrice / item.quantity);
      }

      item?.modifiers?.forEach((modifier) => {
        const modifierQuantity =
          order?.paymentStatus !== OrderPaymentStatusEnum.CONFIRMED
            ? modifier.quantity - modifier.refundedQuantity
            : 0;

        newRefundItemsMap[modifier.lineId] = {
          max: modifier.quantity - modifier.refundedQuantity,
          curr: modifierQuantity,
          fullQuantity: modifier.quantity,
          pricePerItem: modifier.prices.discountedPrice / modifier.quantity,
        };

        if (modifierQuantity > 0) {
          newPriceToRefund +=
            modifierQuantity *
            (modifier.prices.discountedPrice / modifier.quantity);
        }
      });
    });

    orderResponse?.coupons?.forEach((coupon) => {
      const couponQuantity =
        order?.paymentStatus !== OrderPaymentStatusEnum.CONFIRMED
          ? coupon.quantity - coupon.refundedQuantity
          : 0;

      newRefundItemsMap[coupon.lineId] = {
        max: coupon.quantity - coupon.refundedQuantity,
        curr: couponQuantity,
        fullQuantity: coupon.quantity,
        pricePerItem: coupon.prices.discountedPrice / coupon.quantity,
      };

      if (coupon.quantity > 0) {
        newPriceToRefund +=
          couponQuantity * (coupon.prices.discountedPrice / coupon.quantity);
      }
    });

    setPriceToRefund(newPriceToRefund);

    setRefundItemsMap(newRefundItemsMap);

    checkIsRefundAvailable();
  };

  const getOrder = async () => {
    try {
      setLoading((old: LoadingType) => ({
        ...old,
        globalLoading: true,
      }));
      const response = await ordersService.getOrderById(id as string);

      setOrder(response);

      recreateRefundItemsMap(response);
    } catch (e) {
      // debug.error(e);
    } finally {
      setLoading((old: LoadingType) => ({
        ...old,
        globalLoading: false,
      }));
    }
  };

  const onDeleteItemToRefund = (lineId: number, quantity: number) => {
    if (!refundItemsMap[lineId]) {
      return;
    }

    if (refundItemsMap[lineId].curr - quantity >= 0) {
      const newMap: Record<number, RefundItemMetadata> = {};

      Object.assign(newMap, refundItemsMap);

      newMap[lineId].curr -= quantity;

      setPriceToRefund(
        (oldPriceToRefund) =>
          oldPriceToRefund - refundItemsMap[lineId].pricePerItem * quantity,
      );

      setRefundItemsMap(newMap);
    }
  };

  const onPressCancelOrderBtn = async () => {
    if (order) {
      try {
        setLoading((old: LoadingType) => ({
          ...old,
          finishOrderLoading: true,
        }));

        const result = await ordersService.rawUpdateOrder(order.id, {
          posStatus: OrderPOSStatusEnum.CANCELLED,
          internalStatus: OrderInternalStatusEnum.CANCELED,
        });

        setOrder(result);

        recreateRefundItemsMap(result);

        toastify.success('Заказ отменен');
      } catch (e) {
        console.log(e);

        toastify.error('Не удалось отменить заказ');
      } finally {
        setLoading((old: LoadingType) => ({
          ...old,
          finishOrderLoading: false,
        }));
      }
    }
  };

  const onPressReturnMoneyBtn = async () => {
    if (order) {
      try {
        setLoading((old: LoadingType) => ({
          ...old,
          returnMoneyLoading: true,
        }));

        const refundItems: RefundOrderDto['items'] = [];

        Object.getOwnPropertyNames(refundItemsMap).forEach((lineId) => {
          if (refundItemsMap[+lineId].curr > 0) {
            refundItems.push({
              lineId: +lineId,
              quantity: refundItemsMap[+lineId].curr,
            });
          }
        });

        setIsRefundModalOpen(false);

        await ordersService.refundOrder(order.id, {
          type: RefundOrderTypeEnum.PARTIAL_REFUND,
          items: refundItems,
          isRefundDelivery: isRefundDeliveryPrice ?? false,
        });

        getOrder();

        toastify.success('Возврат средств прошёл успешно');
      } catch (e) {
        toastify.error('Не удалось вернуть деньги');
      } finally {
        setLoading((old: LoadingType) => ({
          ...old,
          returnMoneyLoading: false,
        }));
      }
    }
  };

  const onPressFinishOrderBtn = async () => {
    if (order) {
      try {
        setLoading((old: LoadingType) => ({
          ...old,
          finishOrderLoading: true,
        }));

        const result = await ordersService.rawUpdateOrder(order.id, {
          internalStatus: OrderInternalStatusEnum.COMPLETED,
        });

        setOrder(result);

        recreateRefundItemsMap(result);

        toastify.success('Заказ завершён');
      } catch (e) {
        toastify.error('Не удалось завершить заказ');
      } finally {
        setLoading((old: LoadingType) => ({
          ...old,
          finishOrderLoading: false,
        }));
      }
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  if (loading.globalLoading || !order) {
    return <LoadingContent />;
  }

  return (
    <>
      {isRefundModalOpen && (order.items?.length || order.coupons?.length) && (
        <Styled.RefundMoneyModalBackground>
          <Styled.RefundMoneyModal>
            <Receipt
              data={order}
              options={{
                mode: 'REFUND',
                priceToRefund,
                isRefundDeliveryPrice,
                onAddItemToRefund,
                onDeleteItemToRefund,
                refundItemsMap,
                onRefundDeliveryPrice,
                partialRefundAvailable,
              }}
            />
            <Styled.ButtonsContainer>
              <Button
                label="Отменить"
                variant={ButtonVariant.Error}
                className="button"
                onClick={() => setIsRefundModalOpen(false)}
                loading={loading.cancelOrderLoading}
              />
              <Button
                label="Подтвердить"
                onClick={onPressReturnMoneyBtn}
                className="button"
                loading={loading.returnMoneyLoading}
                disabled={!isRefundAvailable}
              />
            </Styled.ButtonsContainer>
          </Styled.RefundMoneyModal>
        </Styled.RefundMoneyModalBackground>
      )}
      <Head
        {...{
          label: `Заказ № ${order.orderNumber}`,
          underlineVariant: HeadUnderlineVariant.Primary,
        }}
      />
      <Styled.Separator />
      <OrderSummary data={order} />
      <Styled.Separator />
      <OrderRestaurant data={order.restaurant} />
      <Styled.Separator />
      {order.address && (
        <>
          <OrderAddress data={order.address} />
          <Styled.Separator />
        </>
      )}
      {order.payments && (
        <>
          <OrderPayments data={order.payments} />
          <Styled.Separator />
        </>
      )}
      {(order.items?.length || order.coupons?.length) && (
        <Receipt data={order} options={{ mode: 'VIEW' }} />
      )}
      {/* <Styled.Separator /> */}
      <Styled.ButtonsContainer>
        <Button
          label="Отменить"
          className="button"
          onClick={onPressCancelOrderBtn}
          loading={loading.cancelOrderLoading}
          disabled={
            order.posStatus === OrderPOSStatusEnum.CANCELLED ||
            order.posStatus === OrderPOSStatusEnum.DELIVERED ||
            (order.posStatus === OrderPOSStatusEnum.READY &&
              order.deliveryType === OrderDeliveryTypeEnum.pickup)
          }
        />
        <Button
          label="Вернуть деньги"
          onClick={() => setIsRefundModalOpen(true)}
          className="button"
          loading={loading.returnMoneyLoading}
          disabled={
            (order.paymentStatus !== OrderPaymentStatusEnum.CONFIRMED &&
              order.paymentStatus !== OrderPaymentStatusEnum.PARTIAL_REFUNDED &&
              order.paymentStatus !== OrderPaymentStatusEnum.PARTIAL_REVERSED &&
              order.paymentStatus !== OrderPaymentStatusEnum.AUTHORIZED &&
              order.paymentStatus !== OrderPaymentStatusEnum.COMPLETED) ||
            order.payments.paymentType === OrderPaymentEnum.CASH ||
            !(order.items?.length || order.coupons?.length)
          }
        />
        <Button
          label="Завершить"
          className="button"
          onClick={onPressFinishOrderBtn}
          loading={loading.finishOrderLoading}
          disabled={
            order.internalStatus !== OrderInternalStatusEnum.IN_PROGRESS
          }
        />
      </Styled.ButtonsContainer>
      <Typography
        size={TypographySize.s}
        tag={TypographyTag.span}
        style={{ marginTop: ptr(40) }}
      >
        * При нажатии "Отмена" произойдет отмена заказа в ПОС-системе, а также,
        в системе программы лояльности Mindbox, заказ будет помечен как
        отмененный. Внутренний системный статус заказа станет "Отменен".
      </Typography>
      <Typography
        size={TypographySize.s}
        tag={TypographyTag.span}
        style={{ marginTop: ptr(20) }}
      >
        * При нажатии "Возврат средств", запустится процесс возврата средств
        клиенту. Заказ можно возвращать частично.
      </Typography>
      <Typography
        size={TypographySize.s}
        tag={TypographyTag.span}
        style={{ marginBottom: ptr(20), marginTop: ptr(20) }}
      >
        * При нажатии "Завершить" произойдет смена внутреннего системного
        статуса на "Завершен". Заказ переместиться в рездел завершенных.
      </Typography>
    </>
  );
};
