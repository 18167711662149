import { ptr } from '#styles.utils';

import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { OrderRestaurantResponseType } from '#services/orders/responses/order-restaurant.type';

type ViewProps = {
  data: OrderRestaurantResponseType;
};

const orderRestaurantLocalizationMap = {
  id: 'Идентификатор',
  name: 'Наименование',
  address: 'Адрес ресторана',
};

const OrderRestaurant: React.FC<ViewProps> = ({ data }) => (
  <>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.span}
      style={{ marginBottom: ptr(20) }}
    >
      Ресторан
    </Typography>
    <Table
      {...{
        // loading,
        header: {
          cells: {},
        },
        rows: [
          {
            cells: {
              0: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.m}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {orderRestaurantLocalizationMap.id}
                  </Typography>
                ),
              },
              1: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.m}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Medium}
                  >
                    {data.id}
                  </Typography>
                ),
              },
            },
          },
          {
            cells: {
              0: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.m}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {orderRestaurantLocalizationMap.name}
                  </Typography>
                ),
              },
              1: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.m}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Medium}
                  >
                    {data.name}
                  </Typography>
                ),
              },
            },
          },
          {
            cells: {
              0: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.m}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {orderRestaurantLocalizationMap.address}
                  </Typography>
                ),
              },
              1: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.m}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Medium}
                  >
                    {data.address}
                  </Typography>
                ),
              },
            },
          },
        ],
      }}
    />
    {/* {data.internalStatus !== OrderInternalStatusEnum.CANCELED && (
      <Styled.ButtonsContainer>
        <Button
          label="Отменить"
          className="button"
          onClick={() => onPressCancelOrderBtn(data.id)}
          loading={loading.cancelOrderLoading}
        />
        <Button
          label="Вернуть деньги"
          onClick={() => onPressReturnMoneyBtn(data.id)}
          className="button"
          loading={loading.returnMoneyLoading}
        />
        <Button
          label="Завершить"
          className="button"
          onClick={() => onPressFinishOrderBtn(data.id)}
          loading={loading.finishOrderLoading}
        />
      </Styled.ButtonsContainer>
    )} */}
  </>
);

export default OrderRestaurant;
