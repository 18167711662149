import { useState } from 'react';

import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import * as Styled from './styles';

import { useAppSelector } from '#hooks';

import {
  Button,
  InputField,
  MaskInputField,
  SingleSelectField,
  DateInputField,
  IndicatorOption,
  DateInput,
  toastify,
} from '#ui-kit';

import { FormContainer, FormSection, RadioButtonFieldForm } from '#components';

import {
  extractDigits,
  formatPhoneNumber,
  phoneNumberValidationSchema,
  usersStatusOptions,
} from '#utils';

import debug from '#services/debug.service';
import { UserService } from '#services/user';
import {
  Sex,
  UserRole,
  UserStatus,
} from '#services/user/responses/user.response';

import { FormProps, InitialValuesProps } from './types/Form.types';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email('Введите корректный адрес электронной почты'),
  phoneNumber: phoneNumberValidationSchema.required(
    'Поле обязательно для заполнения',
  ),
});

const Form: React.FC<FormProps> = ({
  data: {
    createdAt,
    status,
    sex,
    birthDate,
    name,
    email,
    phoneNumber,
    isEmailConfirmed: initialEmailConfirmed,
    role,
  },
}) => {
  const userService = new UserService();

  const { data: currentUserData } = useAppSelector(({ user }) => user);

  const { userId } = useParams();
  const navigate = useNavigate();

  const [isEmailConfirmed, setIsEmailConfirmed] = useState(
    initialEmailConfirmed,
  );

  const [emailState, setEmailState] = useState(email);

  const [{ loading }, setLoading] = useState({
    loading: false,
  });

  if (!currentUserData) {
    return <>Error</>;
  }

  const initialValues: InitialValuesProps = {
    sex,
    role,
    name,
    email,
    status,
    phoneNumber: formatPhoneNumber(phoneNumber),
    birthDate: birthDate ? new Date(birthDate) : null,
  };

  const onSubmit = async ({
    email: $email,
    status: $status,
    birthDate: $birthDate,
    phoneNumber: $phoneNumber,
    ...rest
  }: InitialValuesProps) => {
    const isAdminWantDeleteUser =
      $status === UserStatus.DELETED && $status !== status;

    const requestShouldContinue = isAdminWantDeleteUser
      ? confirm('Вы действительно хотите удалить пользователя?')
      : true;

    if (userId && requestShouldContinue) {
      setLoading((oldState) => ({
        ...oldState,
        loading: true,
      }));

      try {
        if ($email && emailState !== $email) {
          await userService.updateEmail(userId, {
            email: $email,
          });

          setIsEmailConfirmed(false);

          setEmailState($email);
        }

        if (!$email && emailState !== $email) {
          await userService.resetEmail(userId);

          setIsEmailConfirmed(false);

          setEmailState(null);
        }

        await userService.updateUser(userId, {
          ...rest,
          status: $status === UserStatus.DELETED ? status : $status,
          birthDate: $birthDate ? $birthDate.toISOString() : null,
          phoneNumber: extractDigits($phoneNumber),
        });

        if (isAdminWantDeleteUser) {
          await userService.deleteUser(userId);

          navigate(role === UserRole.ADMIN ? '/users/admins' : '/users');
        }

        toastify.success('Данные пользователя успешно изменены');
      } catch (err) {
        debug.error('🧑🏻‍💻 ~ User ~ Form ~ onSubmit ~ err:', err);

        toastify.error('Произошла ошибка при изменении данных пользователя');
      } finally {
        setLoading((oldState) => ({
          ...oldState,
          loading: false,
        }));
      }
    }
  };

  return (
    <Styled.FormikContainer>
      <Formik
        {...{
          initialValues,
          validationSchema,
          onSubmit,
        }}
      >
        <FormContainer
          leftSideActions={
            <Button
              className="user-form__submit"
              label="Сохранить"
              loading={loading}
            />
          }
        >
          <FormSection
            {...{
              label: 'Имя пользователя',
            }}
          >
            <InputField
              name="name"
              placeholder="Введите имя пользователя"
              clearable
              disabled={loading}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Электронная почта',
              description:
                !isEmailConfirmed && emailState
                  ? 'Электронная почта не подтверждена'
                  : undefined,
            }}
          >
            <InputField
              name="email"
              placeholder="Введите электронную почту"
              clearable
              disabled={loading}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Номер телефона',
              isRequired: true,
            }}
          >
            <MaskInputField
              name="phoneNumber"
              placeholder="Введите номер телефона"
              mask="+7 (999) 999-99-99"
              disabled={loading}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Пол',
            }}
          >
            <SingleSelectField
              name="sex"
              placeholder="Укажите пол пользователя"
              clearable
              disabled={loading}
              options={[
                {
                  value: Sex.MALE,
                  label: 'Мужской',
                },
                {
                  value: Sex.FEMALE,
                  label: 'Женский',
                },
              ]}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Дата рождения',
            }}
          >
            <DateInputField
              name="birthDate"
              dateFormat="dd.MM.yyyy"
              placeholder="Укажите дату рождения"
              maxDate={new Date()}
              clearable
              disabled={loading}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Статус',
            }}
          >
            <SingleSelectField
              name="status"
              options={usersStatusOptions}
              disabled={loading}
              components={{ Option: IndicatorOption }}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Дата создания',
            }}
          >
            <DateInput
              value={new Date(createdAt)}
              name="createdAt"
              dateFormat="d MMMM, yyyy HH:mm"
              readOnly
              disabled={loading}
              onChange={() => {}}
            />
          </FormSection>
          {(role === UserRole.ADMIN || role === UserRole.SUPERADMIN) &&
            currentUserData.role === UserRole.SUPERADMIN && (
              <FormSection
                {...{
                  label: 'Роль администратора',
                }}
              >
                <RadioButtonFieldForm
                  title="Администратор"
                  name="role"
                  value={UserRole.ADMIN}
                />
                <RadioButtonFieldForm
                  title="Супер администратор"
                  subtitle="Супер администратор имеет право назначать других пользователей на роль супер администратора, предоставляя им полные права доступа к системе управления."
                  name="role"
                  value={UserRole.SUPERADMIN}
                />
              </FormSection>
            )}
        </FormContainer>
      </Formik>
    </Styled.FormikContainer>
  );
};

export default Form;
