export type PromotionsDto = Readonly<{
  page: number;
  size: number;
  type: PromotionsType;
  mindboxId?: string;
  // restaurantId: string;
  // internalStatus: PromotionStatusEnum;
}>;

export enum PromotionsType {
  DISCOUNT = 'DISCOUNT',
  COUPON = 'COUPON',
  PROMOCODE = 'PROMOCODE',
  NEWS = 'NEWS',
  UNKNOWN = 'UNKNOWN',
}

export type PromotionType = {
  couponsPageFileId: number | null;
  discountsPageFileId: number | null;
  promotionPageFileId: number | null;
  mainPageFileId: number | null;
  description: string;
  type: PromotionsType;
  id: number;
  name: string;
  internalStatus: PromotionStatusEnum;
  sortOrder: number | null;
  startedAt: Date;
  endsAt: Date | null;
  mindboxId: string;
  isShownOnMainPage: boolean;
};

export type PromotionDataType = PromotionType & {
  code: string;
  isForAllProducts: boolean;
  fullDescription: string;
  isShowOnMainPage: boolean;
};

export enum PromotionStatusEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}
