import styled from 'styled-components';
import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

export const Container = styled.div`
  background: ${colors.black._00};
  box-shadow: 0 ${ptr(4)} ${ptr(6)} ${colors.black._02};
  border-radius: ${ptr(8)};
  padding: ${ptr(16)};
  min-height: 180px;
  margin-bottom: ${ptr(16)};
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  //   width: 160px;
  height: 110px;
  border-radius: ${ptr(8)};
  margin-bottom: ${ptr(32)};
`;
