import { FC, useEffect, useState } from 'react';
import { FormWrapper } from './styles';
import { Button, toastify, Typography } from '#ui-kit';
import {
  ButtonSize,
  ButtonVariant,
  TypographySize,
  TypographyTag,
} from '#ui-kit.types';
import { LoadingContent } from '#components';
import debug from '#services/debug.service';
import { SettingsService } from '#services/settings';
import { JsonSchemaFieldTemplate } from './JsonSchemaFieldTemplate';
import { settingsJsonSchemaWidgets } from './widgets';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { SettingResponseDtoType } from 'types';

type SettingFormType = {
  settingKey: string | null;
};

export const SettingForm: FC<SettingFormType> = ({ settingKey }) => {
  const [setting, setSetting] = useState<SettingResponseDtoType | null>(null);
  const [loading, setIsLoading] = useState<boolean>(false);
  const settingsService = new SettingsService();

  const getSetting = async () => {
    try {
      if (settingKey) {
        setIsLoading(true);

        const response = await settingsService.getSettingByKey(settingKey);

        setSetting(response);
      }
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ getSetting ~ err:', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async ({ formData }: any) => {
    try {
      if (settingKey && setting) {
        await settingsService.updateSettingByKey(settingKey, {
          ...setting,
          valueBody: {
            ...setting.valueBody,
            ...formData,
          },
        });

        toastify.success('Настройка успешно обновлена');
      }
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ updateSetting ~ err:', e);
      toastify.error('Не удалось обновить настройку');
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  if (loading || !setting) {
    return <LoadingContent />;
  }

  return (
    <FormWrapper>
      <Typography
        size={TypographySize.xxl}
        tag={TypographyTag.p}
        style={{ textAlign: 'center', marginBottom: '30px' }}
      >
        {setting.title}
      </Typography>
      <Form
        schema={setting.jsonSchema}
        formData={setting.valueBody.value}
        onSubmit={handleSubmit}
        validator={validator}
        showErrorList={false}
        widgets={settingsJsonSchemaWidgets}
        templates={{ FieldTemplate: JsonSchemaFieldTemplate }}
      >
        <Button
          label="Сохранить"
          type="submit"
          variant={ButtonVariant.Primary}
          disabled={loading || !setting}
          size={ButtonSize.Medium}
        />
      </Form>
    </FormWrapper>
  );
};
