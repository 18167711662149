import { ptr } from '#styles.utils';

import { Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue } from '#utils';
import { OrderAddressResponseType } from '#services/orders/responses/order-address.response.type';

type ViewProps = {
  data: OrderAddressResponseType;
};

const orderAddressLocalizationMap: Record<string, string> = {
  city: 'Город',
  flat: 'Квартира',
  house: 'Дом',
  street: 'Улица',
  comment: 'Комментарий',
};

const OrderAddress: React.FC<ViewProps> = ({ data }) => (
  <>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.span}
      style={{ marginBottom: ptr(20) }}
    >
      Адрес доставки
    </Typography>
    <Table
      {...{
        // loading,
        header: {
          cells: {},
        },
        rows: Object.keys(orderAddressLocalizationMap).map(
          (localizationKey) => ({
            cells: {
              0: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.m}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {orderAddressLocalizationMap[localizationKey]}
                  </Typography>
                ),
              },
              1: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.m}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Medium}
                  >
                    {notAvailableValue(
                      data[localizationKey as keyof OrderAddressResponseType],
                    )}
                  </Typography>
                ),
              },
            },
          }),
        ),
      }}
    />
    {/* {data.internalStatus !== OrderInternalStatusEnum.CANCELED && (
      <Styled.ButtonsContainer>
        <Button
          label="Отменить"
          className="button"
          onClick={() => onPressCancelOrderBtn(data.id)}
          loading={loading.cancelOrderLoading}
        />
        <Button
          label="Вернуть деньги"
          onClick={() => onPressReturnMoneyBtn(data.id)}
          className="button"
          loading={loading.returnMoneyLoading}
        />
        <Button
          label="Завершить"
          className="button"
          onClick={() => onPressFinishOrderBtn(data.id)}
          loading={loading.finishOrderLoading}
        />
      </Styled.ButtonsContainer>
    )} */}
  </>
);

export default OrderAddress;
