import { Formik } from 'formik';

import * as Styled from './styles';

import { DateInputField, InputField, TextareaField } from '#ui-kit';

import { FormSection } from '#components';

import { formatPhoneNumber, formatSupportMessageType } from '#utils';

import { SupportMessageResponse } from '../../../../services/support/responses/support-message.response';
import { InitialValuesProps } from './types/Form.types';

const Form: React.FC<{ data: SupportMessageResponse }> = ({ data }) => {
  // const [expandedImage, setExpandedImage] = useState('');
  const initialValues: InitialValuesProps = {
    ...data,
    createdAt: new Date(data.createdAt),
    phoneNumber: formatPhoneNumber(data.phoneNumber),
    type: formatSupportMessageType(data.type) as string,
  };

  // const handleImageClick = (imageId: string) => {
  //   setExpandedImage(imageId);
  // };

  return (
    <Formik
      {...{
        initialValues,
        onSubmit: () => {},
      }}
    >
      <Styled.Container>
        <div>
          <FormSection
            {...{
              label: 'Имя пользователя',
            }}
          >
            <InputField name="name" readOnly placeholder="Имя пользователя" />
          </FormSection>
          <FormSection
            {...{
              label: 'Электронная почта',
            }}
          >
            <InputField name="email" readOnly placeholder="Электронная почта" />
          </FormSection>
          <FormSection
            {...{
              label: 'Номер телефона',
            }}
          >
            <InputField
              name="phoneNumber"
              placeholder="Номер телефона"
              readOnly
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Адрес ресторана',
            }}
          >
            <InputField
              name="restaurantId"
              placeholder="Адрес ресторана"
              readOnly
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Тип обращения',
            }}
          >
            <InputField name="type" placeholder="Тип обращения" readOnly />
          </FormSection>
          <FormSection
            {...{
              label: 'Сообщение',
            }}
          >
            <TextareaField
              name="message"
              placeholder="Сообщение"
              readOnly
              minRows={8}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Изображение',
            }}
          >
            {data.fileIds?.map((imageId, index) => (
              <img
                key={index}
                src={`${process.env.REACT_APP_API_MOBILE_URL}/files/${imageId}`}
                alt={`Image ${index + 1}`}
                style={{ maxWidth: '100%' }}
                // onClick={() => handleImageClick(imageId)}
              />
            ))}
          </FormSection>
          <FormSection
            {...{
              label: 'Дата отправки',
            }}
          >
            <DateInputField
              name="createdAt"
              placeholder="Дата отправки"
              readOnly
              dateFormat="d MMMM, yyyy HH:mm"
            />
          </FormSection>
        </div>
      </Styled.Container>
    </Formik>
  );
};

export default Form;
