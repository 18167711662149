export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Outline = 'outline',
  Error = 'error',
  Warning = 'warning',
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraSmall = 'extra_small',
}

export type ButtonProps = Readonly<
  Partial<{
    className: string;
    label: string;
    variant: ButtonVariant;
    type: 'submit' | 'reset' | 'button';
    size: ButtonSize;
    loading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: (e?: any) => void;
    disabled: boolean;
  }>
>;
