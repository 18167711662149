import styled from 'styled-components';
import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

// import { ptr } from '#styles.utils';
// import { colors } from '#styles.variables';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  line-height: auto;
`;

export const PlusButton = styled.button`
   {
    width: ${ptr(16)};
    height: ${ptr(16)};
    background-color: ${colors.primary._80};
    line-height: ${ptr(16)};
    border-radius: ${ptr(4)};
    color: ${colors.black._00};
    display: flex;
    align-items: center;
    justify-content: center;

    :focus {
      transform: scale(1.1, 1.1);
      outline: none;
    }
  }
`;

export const MinusButton = styled.button`
   {
    width: ${ptr(16)};
    height: ${ptr(16)};
    background-color: ${colors.error._100};
    line-height: ${ptr(16)};
    border-radius: ${ptr(4)};
    color: ${colors.black._00};
    display: flex;
    align-items: center;
    justify-content: center;

    :focus {
      transform: scale(1.1, 1.1);
      outline: none;
    }
  }
`;
