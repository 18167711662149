import { useState } from 'react';

import { Formik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import * as Styled from './styles';
import { colors } from '#styles.variables';

import { Button, Icon, InputField, Typography, toastify } from '#ui-kit';
import {
  IconNames,
  InputTypes,
  TypographySize,
  TypographyTag,
} from '#ui-kit.types';

import debug from '#services/debug.service';
import { UserService } from '#services/user';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Введите пароль')
    .min(8, 'Пароль должен содержать не менее 8 символов')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]+$/,
      'Пароль должен содержать хотя бы одну заглавную букву, одну строчную букву, одну цифру и один специальный символ',
    ),
  repeatPassword: Yup.string()
    .required('Подтверждение пароля обязательно')
    .oneOf([Yup.ref('password')], 'Пароли не совпадают'),
});

const Form = () => {
  const userService = new UserService();

  const { token } = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const initialValues = {
    password: '',
    repeatPassword: '',
  };

  const onSubmit = async ({ password }: { password: string }) => {
    if (token) {
      setLoading(true);
      try {
        await userService.acceptInvite({ password, token });

        toastify.success('Регистрация успешно завершена');

        navigate('/');
      } catch (err) {
        debug.error('🧑🏻‍💻 ~ Registration ~ Form ~ onSubmit ~ err:', err);

        toastify.error(
          'Не удалось зарегистрироваться. Свяжитесь с администратором',
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Styled.Container>
      <Styled.BackLink to="/">
        <Icon
          name={IconNames['arrow-left']}
          fill={colors.black._40}
          width={20}
          height={20}
        />
        <Styled.BackButtonText>Вернуться к авторизации</Styled.BackButtonText>
      </Styled.BackLink>
      <Styled.FormWrapper>
        <Styled.Title
          size={TypographySize.xl}
          color={colors.secondary._100}
          tag={TypographyTag.h1}
        >
          Регистрация
          <Styled.Underline src="/assets/images/underlines/underline(2).png" />
        </Styled.Title>
        <Formik
          {...{
            initialValues,
            validationSchema,
            onSubmit,
          }}
        >
          <Styled.Form>
            <InputField
              label="Пароль"
              name="password"
              type={InputTypes.Password}
              maxLength={16}
              disabled={loading}
            />
            <InputField
              label="Повторите пароль"
              name="repeatPassword"
              type={InputTypes.Password}
              maxLength={16}
              disabled={loading}
            />
            <Button
              label="Зарегистрироваться"
              className="form__register-button"
              loading={loading}
            />
          </Styled.Form>
        </Formik>
      </Styled.FormWrapper>
      <Styled.Footer>
        <Typography size={TypographySize.xs} color={colors.secondary._100}>
          Copyright © {new Date().getFullYear()}
        </Typography>
      </Styled.Footer>
    </Styled.Container>
  );
};

export default Form;
