import classNames from 'classnames';

import * as Styled from './styles';
import { colors } from '#styles.variables';

import { Spinner } from '#ui-kit';
import { SpinnerSize, TypographyTag, TypographyWeight } from '#ui-kit.types';

import { ButtonProps, ButtonVariant } from './types/Button.types';

const Button: React.FC<ButtonProps> = ({
  variant = ButtonVariant.Primary,
  type = 'submit',
  label = '',
  className,
  size,
  loading = false,
  onClick = () => {},
  disabled = false,
}) => (
  <Styled.Button
    {...{
      loading,
      disabled,
      type,
      size,
      variant,
      onClick: () => {
        if (!loading) {
          onClick();
        }
      },
      className: classNames(`custom-button button__${variant}`, className),
    }}
  >
    <Styled.Label
      {...{
        loading,
        variant,
        tag: TypographyTag.span,
        weight: TypographyWeight.Light,
      }}
    >
      {label}
    </Styled.Label>
    {loading && (
      <Spinner
        size={SpinnerSize.Medium}
        color={
          variant === ButtonVariant.Outline ? colors.primary._100 : undefined
        }
      />
    )}
  </Styled.Button>
);

export default Button;
