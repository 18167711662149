import { useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import * as Styled from './styles';

import { useAppDispatch, useAppSelector } from '#hooks';

import {
  Button,
  InputField,
  MaskInputField,
  SingleSelectField,
  DateInputField,
  DateInput,
  toastify,
} from '#ui-kit';

import { FormContainer, FormSection } from '#components';

import {
  extractDigits,
  formatPhoneNumber,
  phoneNumberValidationSchema,
} from '#utils';

import { userSliceActions } from '#store/user';

import debug from '#services/debug.service';
import { UserService } from '#services/user';
import { Sex } from '#services/user/responses/user.response';

import { InitialValuesProps } from './types/Form.types';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email('Введите корректный адрес электронной почты'),
  phoneNumber: phoneNumberValidationSchema.required(
    'Поле обязательно для заполнения',
  ),
});

const Form: React.FC = () => {
  const userService = new UserService();

  const { data } = useAppSelector(({ user }) => user);

  const dispatch = useAppDispatch();

  if (!data) {
    return <>Error</>;
  }

  const {
    isEmailConfirmed: initialEmailConfirmed,
    email,
    sex,
    name,
    phoneNumber,
    birthDate,
    createdAt,
  } = data;

  const [isEmailConfirmed, setIsEmailConfirmed] = useState(
    initialEmailConfirmed,
  );

  const [emailState, setEmailState] = useState(email);

  const [{ loading }, setLoading] = useState({
    loading: false,
  });

  const initialValues: InitialValuesProps = {
    sex,
    name,
    email,
    phoneNumber: formatPhoneNumber(phoneNumber),
    birthDate: birthDate ? new Date(birthDate) : null,
  };

  const onSubmit = async ({ email: $email, ...rest }: InitialValuesProps) => {
    if (data) {
      setLoading((oldState) => ({
        ...oldState,
        loading: true,
      }));

      try {
        if ($email && emailState !== $email) {
          await userService.updateEmail(`${data.id}`, {
            email: $email,
          });

          setIsEmailConfirmed(false);

          setEmailState($email);
        }

        if (!$email) {
          await userService.resetEmail(`${data.id}`);

          setIsEmailConfirmed(false);

          setEmailState(null);
        }

        const response = await userService.updateUser(`${data.id}`, {
          ...rest,
          birthDate: rest.birthDate ? rest.birthDate.toISOString() : null,
          phoneNumber: extractDigits(rest.phoneNumber),
        });

        dispatch(userSliceActions.restoreUserData(response));

        toastify.success('Ваши данные успешно изменены');
      } catch (err) {
        debug.error('🧑🏻‍💻 ~ Profile ~ Form ~ onSubmit ~ err:', err);

        toastify.error('Произошла ошибка при изменении ваших данных');
      } finally {
        setLoading((oldState) => ({
          ...oldState,
          loading: false,
        }));
      }
    }
  };

  return (
    <Styled.FormikContainer>
      <Formik
        {...{
          initialValues,
          validationSchema,
          onSubmit,
        }}
      >
        <FormContainer
          leftSideActions={
            <Button
              className="user-form__submit"
              label="Сохранить"
              loading={loading}
            />
          }
        >
          <FormSection
            {...{
              label: 'Имя пользователя',
            }}
          >
            <InputField
              name="name"
              placeholder="Введите имя пользователя"
              clearable
              disabled={loading}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Электронная почта',
              description:
                !isEmailConfirmed && emailState
                  ? 'Электронная почта не подтверждена'
                  : undefined,
            }}
          >
            <InputField
              name="email"
              placeholder="Введите электронную почту"
              clearable
              disabled={loading}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Номер телефона',
              isRequired: true,
            }}
          >
            <MaskInputField
              name="phoneNumber"
              placeholder="Введите номер телефона"
              mask="+7 (999) 999-99-99"
              disabled={loading}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Пол',
            }}
          >
            <SingleSelectField
              name="sex"
              placeholder="Укажите пол пользователя"
              clearable
              disabled={loading}
              options={[
                {
                  value: Sex.MALE,
                  label: 'Мужской',
                },
                {
                  value: Sex.FEMALE,
                  label: 'Женский',
                },
              ]}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Дата рождения',
            }}
          >
            <DateInputField
              name="birthDate"
              dateFormat="dd.MM.yyyy"
              placeholder="Укажите дату рождения"
              maxDate={new Date()}
              clearable
              disabled={loading}
            />
          </FormSection>
          <FormSection
            {...{
              label: 'Дата создания',
            }}
          >
            <DateInput
              value={new Date(createdAt)}
              name="createdAt"
              dateFormat="d MMMM, yyyy HH:mm"
              readOnly
              disabled={loading}
              onChange={() => {}}
            />
          </FormSection>
        </FormContainer>
      </Formik>
    </Styled.FormikContainer>
  );
};

export default Form;
