import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';
import debug from '#services/debug.service';
import { PromotionsService } from '#services/promotions';
import { PromotionDataType } from '#services/promotions/dto/get-promotions.dto';
import PromotionView from './containers/PromotionView/Index';

export type LoadingType = {
  globalLoading: boolean;
};

export default () => {
  const promotionsService = new PromotionsService();

  const [promotion, setPromotion] = useState<PromotionDataType | null>(null);

  const [loading, setLoading] = useState({
    globalLoading: false,
  });

  const { id } = useParams();

  const getPromotion = async (shouldLoad = true) => {
    try {
      if (shouldLoad) {
        setLoading((old: LoadingType) => ({
          ...old,
          globalLoading: true,
        }));
      }
      const response = await promotionsService.getPromotionById({
        promotionId: Number(id),
      });
      setPromotion(response);
    } catch (e) {
      debug.error('', e);
    } finally {
      if (shouldLoad) {
        setLoading((old: LoadingType) => ({
          ...old,
          globalLoading: false,
        }));
      }
    }
  };

  useEffect(() => {
    getPromotion();
  }, []);

  if (loading.globalLoading) {
    return <LoadingContent />;
  }
  return (
    <>
      <Head
        {...{
          label: promotion?.name,
          underlineVariant: HeadUnderlineVariant.Primary,
        }}
      />
      {promotion ? (
        <PromotionView data={promotion} getPromotion={getPromotion} />
      ) : null}
    </>
  );
};
