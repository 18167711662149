import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';
import { Typography } from '#ui-kit';
import { TypographySize, TypographyTag, TypographyWeight } from '#ui-kit.types';
import { OrderItemResponseType } from '#services/orders/responses/order-item.response';
import * as Styled from './style';

type RefundItemMetadata = {
  max: number;
  curr: number;
  fullQuantity: number;
  pricePerItem: number;
};

type LineQuantityProps = {
  data: Pick<
    OrderItemResponseType,
    'quantity' | 'refundedQuantity' | 'lineId' | 'prices'
  > & {
    isModifier: boolean;
    refundItemsMap: Record<number, RefundItemMetadata>;
    partialRefundAvailable: boolean;
    onMinus: (lineId: number, quantity: number, itemFullPrice: number) => void;
    onPlus: (lineId: number, quantity: number, itemFullPrice: number) => void;
  };
};

const LineQuantityCounter: React.FC<LineQuantityProps> = ({ data }) => (
  <Styled.Container>
    <Styled.MinusButton
      onClick={() => data.onMinus(data.lineId, 1, data.prices.discountedPrice)}
      style={{
        backgroundColor:
          data.refundItemsMap[data.lineId]?.curr > 0 &&
          data.partialRefundAvailable
            ? colors.error._100
            : colors.black._40,
      }}
      disabled={!data.partialRefundAvailable}
    >
      {'-'}
    </Styled.MinusButton>
    <Typography
      size={TypographySize.m}
      tag={TypographyTag.span}
      weight={
        data.isModifier ? TypographyWeight.Light : TypographyWeight.Medium
      }
      style={{
        marginLeft: ptr(6),
        marginRight: ptr(6),
        width: ptr(40),
        textAlign: 'center',
      }}
    >
      {(data.refundItemsMap[data.lineId]?.curr || 0) +
        '  /  ' +
        data.refundItemsMap[data.lineId]?.max}
    </Typography>
    <Styled.PlusButton
      onClick={() => data.onPlus(data.lineId, 1, data.prices.discountedPrice)}
      style={{
        backgroundColor:
          (data.refundItemsMap[data.lineId]?.max >
            data.refundItemsMap[data.lineId]?.curr ||
            0) &&
          data.partialRefundAvailable
            ? colors.primary._80
            : colors.black._40,
      }}
      disabled={!data.partialRefundAvailable}
    >
      {'+'}
    </Styled.PlusButton>
  </Styled.Container>
);

export default LineQuantityCounter;
